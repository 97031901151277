<div class="login-wrapper">
    <div class="box">
        <div class="content-wrap">
            <h1>{{ 'Acquia Optimize password reset' | translate }}</h1>

            <form name="loginForm" autocomplete="off">
                <p>
                    {{ 'Before we can proceed with your request we need your...' | translate }}
                </p>
                <div class="form-group">
                    <input autofocus class="form-control" name="email" type="email" [(ngModel)]="email" placeholder="{{'Email' | translate}}" required>
                </div>

                <button class="btn btn-flat primary login" (click)="sendPasswordResetRequest()">{{ 'Reset password' | translate }}</button>
            </form>
        </div>
    </div>
</div>
