<!-- Menu start -->
<nav class="navbar navbar-expand-md navbar-dark bg-dark py-0">
    <div class="container-fluid">
        <span class="logo-wrapper ml-3">
            <a
                class="navbar-brand logo-wrapper-link"
                uiSref="base.agreement_selector.index"
            >
                <img class="logo" src="/images/Optimize-White.svg" alt="Logo"/>
            </a>
            <span class="navbar-brand sub-brand inner-t-medium">Partner platform</span>
        </span>
        <span id="navbarToggleDescription" hidden>{{ 'Toggle navigation' | translate }}</span>
        <button
            class="navbar-toggler mr-1 mr-sm-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-labelledby="navbarToggleDescription"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbar">
            <ul class="navbar-nav mb-lg-0 align-items-md-center">
                <li
                    class="nav-item"
                    tooltip-placement="bottom"
                    *ngIf="showResellerAdminSettings"
                    ngbTooltip="{{ 'Reseller Admin settings' | translate}}"
                    tooltip-append-to-body="true"
                >
                    <span id="resellerAdminSettingsDescription" hidden>{{ 'Reseller Admin settings' | translate }}</span>
                    <a
                        class="active d-flex align-items-center justify-content-sm-center"
                        aria-labelledby="resellerAdminSettingsDescription"
                        uiSref="base.admin.reseller_admin.users.index"
                    >
                        <mon-icon [icon]="['ACTIONS', 'EDIT']" class="fa-lg" />
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="showBackendAdminSettings"
                    ngbTooltip="{{ 'Backend Admin settings' | translate}}"
                    tooltip-placement="bottom"
                    tooltip-append-to-body="true"
                >
                    <span id="backendAdminSettingsDescription" hidden>{{ 'Backend Admin settings' | translate }}</span>
                    <a
                        class="active d-flex align-items-center justify-content-sm-center"
                        aria-labelledby="backendAdminSettingsDescription"
                        uiSref="base.backend_admin.admin.accessibility_checks.index"
                    >
                        <mon-icon [icon]="['ACTIONS', 'EDIT']" class="fa-lg" />
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <button
                        class="no-button-appearance"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <ngx-avatar
                            class="inline-block-important outer-t-small outer-b-small outer-l-small outer-r-medium"
                            [name]="user?.initials"
                            [round]="true"
                            [textSizeRatio]="2"
                            size="30"
                        />
                    </button>
                    <span id="navbarDropdownDescription" hidden>{{ 'Personal menu' | translate }}</span>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownDescription">
                        <li>
                            <div class="inner-l-small inner-r-small text-nowrap mon-table-cell">
                                <ngx-avatar
                                    class="inline-block-important outer-t-smurf"
                                    [name]="user?.initials"
                                    [round]="true"
                                    [textSizeRatio]="2"
                                    size="65"
                                />
                                <div class="inline-block-important outer-l-small col-top">
                                    <h4>{{ user?.name }}</h4>
                                    <span class="text-grey">{{ user?.email }}</span><br/>
                                    <span class="text-link" role="button" (click)="showProfile()">{{ 'Edit my profile' | translate }}</span>
                                </div>
                            </div>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                            <a uiSref="base.agreement_selector.index">{{ 'Switch account' | translate }}</a>
                        </li>
                        <li>
                            <a href (click)="openVersion($event)">{{ 'About' | translate }}</a>
                        </li>
                        <li>
                            <a href (click)="signOut($event)">{{ 'Log out' | translate }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
